exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bioblinds-2nd-edition-cowshed-js": () => import("./../../../src/pages/bioblinds-2nd-edition-cowshed.js" /* webpackChunkName: "component---src-pages-bioblinds-2nd-edition-cowshed-js" */),
  "component---src-pages-bioblinds-hub-js": () => import("./../../../src/pages/bioblinds-hub.js" /* webpackChunkName: "component---src-pages-bioblinds-hub-js" */),
  "component---src-pages-diy-editions-6-js": () => import("./../../../src/pages/diy-editions-6.js" /* webpackChunkName: "component---src-pages-diy-editions-6-js" */),
  "component---src-pages-first-edition-js": () => import("./../../../src/pages/first-edition.js" /* webpackChunkName: "component---src-pages-first-edition-js" */),
  "component---src-pages-get-in-touch-js": () => import("./../../../src/pages/get-in-touch.js" /* webpackChunkName: "component---src-pages-get-in-touch-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inspiration-js": () => import("./../../../src/pages/inspiration.js" /* webpackChunkName: "component---src-pages-inspiration-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-plant-research-hub-js": () => import("./../../../src/pages/plant-research-hub.js" /* webpackChunkName: "component---src-pages-plant-research-hub-js" */)
}

