module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","icon":"src/images/home/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"689aa622ed65b4d89d4b6b1048fd2674"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-goatcounter/gatsby-browser.js'),
      options: {"plugins":[],"code":"bioblinds","exclude":[],"pageTransitionDelay":0,"head":false,"pixel":false,"allowLocal":false,"localStorageKey":"skipgc","referrer":false,"urlCleanup":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
